import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import OrderItem from "./OrderItem";
import Slide from "@mui/material/Slide";
import { useMutation } from "@tanstack/react-query";
import { request } from "../../api/request";
import DeleteIcon from "@mui/icons-material/Delete";
import { useReactToPrint } from "react-to-print";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const moveToRunnerRequest = (id) => {
  return request({
    url: `/order-to-paid/${id}`,
    method: "patch",
  });
};
const DeleteRequest = (id) => {
  return request({
    url: `/orders/${id}`,
    method: "delete",
  });
};

const OrderCard = ({ order }) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [alterOpen, setAlterOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [messageType, setMessageType] = React.useState("error");
  const [bgColor, setBgColor] = React.useState('red');
  useEffect(() => {
    const timer = setTimeout(() => {
        setBgColor('normalBackground'); // Replace 'normalBackground' with your default background color or class
    }, 3000);

    return () => clearTimeout(timer);
}, []); // Empty dependency array ensures this runs only once 
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };
  const handelAlterClose = () => {
    setAlterOpen();
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const toRunnerHandler = () => {
    handleClickOpen();
  };

  const toRunnerMutation = useMutation({
    mutationKey: [`move-order-${order.id}-to-runner`],
    mutationFn: moveToRunnerRequest,
    onSuccess: (data) => {
      setMessage("Order Move On To Runner Successfully");
      setMessageType("error");
      setAlterOpen(true);
      window.location.reload();
    },
    onError: (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401: {
            setMessage("you are not authorize to do this operation");
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
          case 422: {
            setMessage("wrnog data given to server");
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
          case 500: {
            setMessage("we have a problem in our server , come later");
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
          case 404: {
            setMessage("we out of space , we can't find your destenation");
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
          default: {
            setMessage(
              "unkown error accoure : request falid with status code" +
                error.response.status
            );
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
        }
      } else if (error.request) {
        setMessage(
          "server response with nothing , Check your internet connection or contact support if the problem persists"
        );
        setMessageType("error");
        setAlterOpen(true);
      } else {
        setMessage("unknow error : " + error.message);
        setMessageType("error");
        setAlterOpen(true);
      }
    },
  });
  const toDelete = useMutation({
    mutationKey: [`delete-order-${order.id}`],
    mutationFn: DeleteRequest,
    onSuccess: (data) => {
      setMessage("Order deleted Successfully");
      setMessageType("error");
      setAlterOpen(true);
      window.location.reload();
    },
    onError: (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401: {
            setMessage("you are not authorize to do this operation");
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
          case 422: {
            setMessage("wrnog data given to server");
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
          case 500: {
            setMessage("we have a problem in our server , come later");
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
          case 404: {
            setMessage("we out of space , we can't find your destenation");
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
          default: {
            setMessage(
              "unkown error accoure : request falid with status code" +
                error.response.status
            );
            setMessageType("error");
            setAlterOpen(true);
            break;
          }
        }
      } else if (error.request) {
        setMessage(
          "server response with nothing , Check your internet connection or contact support if the problem persists"
        );
        setMessageType("error");
        setAlterOpen(true);
      } else {
        setMessage("unknow error : " + error.message);
        setMessageType("error");
        setAlterOpen(true);
      }
    },
  });

  const handelConfirm = () => {
    toRunnerMutation.mutate(order.id);
    // printOrder(); // Call the printOrder function after the mutation is successful
  };

  const printOrder = () => {
    const printWindow = window.open("", "_blank");
    console.log("order", order);
    if (printWindow) {
      printWindow.document.write(`
        <html>
        <head>
          <title>Le Salone - فاتورة ضريبية</title>
          <style>
          @page {
            size: 80mm 285mm; /* Set the size of the printed page */
            margin: 0;
          }
            body {
              font-family: 'Arial', sans-serif;
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 285mm; /* Set the height to match your paper size */
              width: 100vw; /* Set the width to the full viewport width */
            }
        
            .receipt-container {
              border: 2px solid #333;
              padding: 20px;
              border-radius: 10px;
              background-color: #f9f9f9;
              height: 100%; /* Set the height to match your paper size */
              width: 100%; /* Adjust the width to fill the paper */
            }
        
            h1, h2 {
              color: #333;
              text-align: center;
            }
        
            p {
              margin-bottom: 5px;
            }
        
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }
        
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: center;
            }
        
            th {
              background-color: #f2f2f2;
            }
        
            tbody tr:nth-child(even) {
              background-color: #f9f9f9;
            }
          </style>
        </head>
        <body>
          <div class="receipt-container">
            <h1>Le Salone</h1>
            <h2>فاتورة ضريبية</h2>
            <p><strong>رقم الفاتورة (Tax ID):</strong> ${order.id}</p>
            <p><strong>تاريخ الفاتورة (Invoice Date):</strong> ${new Date().toLocaleString()}</p>
            <h4>تفاصيل الطلبات (Order Details)</h4>
            <table>
              <thead>
                <tr>
                  <th>Order Item</th>
                  <th>Quantity</th>
                  <th>Single Item Price</th>
                  <th>Total Item Price</th>
                </tr>
              </thead>
              <tbody>
                ${order.order_items
                  .map(
                    (orderItem) => `
                  <tr>
                    <td>${orderItem.meal.name}</td>
                    <td>${orderItem.quantity}</td>
                    <td>${orderItem.meal.price}</td>
                    <td>${orderItem.total} SAR</td>
                  </tr>
                `
                  )
                  .join("")}
              </tbody>
            </table>
          

            <p><strong>Total:</strong>  ${
              order.discount_code!==null &&order.discount_code!=='null'?
              (order.total + (order.total * 0.15) )- ((order.total )*20/100) :
                (order.total + (order.total * 15) / 100)

            } SAR</p>

          </div>
        </body>
        </html>
        
        `);
      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    } else {
      console.error("Failed to open print window");
    }
  };

  const handelConfirmDelete = () => {
    toDelete.mutate(order.id);
  };
  const getTableInfo = () => {
    if (order.table_id >= 1 && order.table_id <= 15) {
      return `B Floor, Chair: ${order.table_id}`;
    } else if (order.table_id >= 16 && order.table_id <= 25) {
      return `G Floor, Chair: ${order.table_id - 15}`;
    } else if (order.table_id >= 26 && order.table_id <= 39) {
      return `First Floor, Room: ${order.table_id - 25}`;
    } else {
      return `Invalid Table ID`;
    }
  };
  return (
    <Box sx={{ backgroundColor: bgColor }}>
      <Box
        sx={{
          boxShadow: "0 0px 10px 0px #b91c1c",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // padding : '8px',
            borderRadius: "8px 8px 0px 0px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              backgroundColor: "#F3F3F3",
              // filter : 'blur(10px)',
              // zIndex : '-1',
            }}
          ></Box>
          <Box
            sx={{
              padding: "10px",
              zIndex: "1",
              backgroundColor: bgColor
            }}
          
          >
            <Typography>{`Order ID: ${order.id}`}</Typography>
            <Typography>{getTableInfo()}</Typography>
            <Typography>{`Payment Method: ${order.payment_method}`}</Typography>
            {order.discount_code!==null &&order.discount_code!=='null'&& 
            <Typography>{`Code: ${order.discount_code}`}</Typography>
          }
          {order.discount_code!==null &&order.discount_code!=='null'?
            <Typography
              sx={{
                marginBottom: "10px",
              }}
            >
              {`Total : ${(order.total + (order.total * 0.15) )- ((order.total )*20/100)} SAR`}
            </Typography>
          : <Typography
          sx={{
            marginBottom: "10px",
          }}
        >
          {`Total : ${order.total + (order.total * 15) / 100} SAR`}
        </Typography>}
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                marginRight: "-20px",
              }}
            >
              <Button
                // color='success'
                // variant='outlined'
                onClick={handleClickOpenDelete}
                sx={{
                  margin: "10px",
                }}
              >
                <DeleteIcon sx={{ color: "red" }} />
              </Button>
            </Box>
            <Box>
              <Button
                color="success"
                variant="outlined"
                onClick={toRunnerHandler}
                sx={{
                  margin: "10px",
                }}
              >
                done
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          {order.order_items.map((orderItem) => (
            <OrderItem key={orderItem.id} orderItem={orderItem} />
          ))}
        </Box>
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirmation Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are You Sure You Want To Confirm This Order?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginX: "15px",
          }}
        >
          <Button
            fullWidth
            color="error"
            variant="outlined"
            sx={{ mt: 3, mb: 2, padding: "10px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            color="success"
            variant="outlined"
            sx={{ mt: 3, mb: 2, padding: "10px" }}
            onClick={handelConfirm}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDelete}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Confirmation Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are You Sure You Want To Remove This Order?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginX: "20px",
          }}
        >
          <Button
            fullWidth
            color="error"
            variant="outlined"
            sx={{ mt: 3, mb: 2, padding: "10px" }}
            onClick={handleCloseDelete}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            color="success"
            variant="outlined"
            sx={{ mt: 3, mb: 2, padding: "10px" }}
            onClick={handelConfirmDelete}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alterOpen}
        autoHideDuration={4000}
        onClose={handelAlterClose}
      >
        <Alert
          onClose={handelAlterClose}
          severity={messageType}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default OrderCard;
