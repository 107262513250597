import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import { logout, useJawadAuthController } from '../context'
import logo from '../assets/Le_salon_logo_page-0001__1_-removebg-preview.png'

const Header = () => {
    const navigate = useNavigate()
    const [, dispatch] = useJawadAuthController()
    const logUserOut = () => {
        logout(dispatch , null)
        navigate('/')
    }
  return (
    <Box
        sx={{
            display : 'flex',
            alignItems : 'center',
            justifyContent : 'space-between',
            height : '80px',
            marginBottom : '15px',
            position:'relative'
        }}
    >
        <Typography>
             <img style={{width:'100px'}} src={logo} />
        </Typography>
        <Typography
            color={'#b91c1c'}
            variant='h5'
            sx={{
                fontSize:{xs:'20px' , sm:'25px' , marginLeft:'-15px'},
            }}
        >
           
            Kitchen Dashboard
        </Typography>
        <Box>
            <Button
                variant='contained'
                color='error'
                onClick={logUserOut}
                sx={{
                    width:{xs:'70px' , sm:'150px'},
                }}
            >
                Logout
            </Button>
        </Box>
    </Box>
  )
}

export default Header