import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import React from 'react'

const OrderItem = ({orderItem}) => {
  return (
    <Card
        sx={{
            boxShadow : 'none',
            width:'100%',
            height:'100%',
            borderBottom:1,
            borderColor:'#E0E0E0',
        }}
    >
      <CardContent
        sx={{
            display : 'flex',
            alignItems : 'center',
            gap : '10px'
        }}
      >
        <Avatar 
          alt="Profile Image" 
          src={'https://api.lesalon.gomaplus.tech'+orderItem?.meal.image} 
          className="profile-image" 
          sx={{ width: 60, height: 60 }}
        />
        <Box>
            <Typography variant="h5" component="h2">
                {orderItem?.meal.name}
            </Typography>
            <Typography>
{orderItem?.meal.description!=='empty'?
             <h2 style={{fontSize:'13px'}}>   {orderItem?.meal.description}</h2>

:
''
}
            </Typography>
            <Typography variant="body2" component="p">
                {`Quantity : ${orderItem.quantity}`}
            </Typography>
            {orderItem.note?
             <Typography variant="body2" component="p">
                {`Note : ${orderItem.note} `}
            </Typography>
            :''}
            <Typography variant="body2" component="p">
                {`Total : ${orderItem.total} SAR`}
            </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

export default OrderItem